<script setup lang="ts">
const showBackToTopButton = ref<boolean>(false)
const lastScrollY = ref<number>(0)

// Determine viewport scroll thresholds based on device type:
// - Use 3 * viewport height on desktop
// - Use 5 * viewport height on mobile
const { breakpoint } = useProjectBreakpoint()
const isDesktop = breakpoint.greater('lg')
const scrollThreshold = computed(() =>
  isDesktop.value ? window.innerHeight * 3 : window.innerHeight * 5
)

const handleBackToTopButtonClick = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

const { y: currentScrollY } = useScroll(window)
watch(
  currentScrollY,
  newY => {
    if (window === undefined) {
      return
    }
    const isScrollingUp = newY < lastScrollY.value
    const hasExceededThreshold = newY > scrollThreshold.value

    showBackToTopButton.value = isScrollingUp && hasExceededThreshold

    lastScrollY.value = newY
  },
  { immediate: true }
)
</script>

<template>
  <button
    v-if="showBackToTopButton"
    class="border-primitives-grey-50 bg-primitives-white group fixed bottom-[calc(var(--sticky-bottom-navigation)+1rem)] right-4 z-[51] flex h-12 w-12 items-center justify-center overflow-hidden border transition-all duration-300 lg:!bottom-6 lg:left-1/2 lg:right-auto lg:h-9 lg:w-9 lg:-translate-x-1/2 lg:hover:w-[110px]"
    @click="handleBackToTopButtonClick"
  >
    <DefaultIconsArrowTop
      class="transition-all duration-300 lg:group-hover:-translate-y-[400%] lg:group-hover:opacity-0"
      :aria-hidden="true"
    />
    <span
      class="text-book-8 absolute bottom-0 hidden translate-y-full items-center justify-center transition-all duration-300 lg:flex lg:group-hover:bottom-[unset] lg:group-hover:translate-y-0"
    >
      {{ $ts('accessibility.backToTop') }}
    </span>
  </button>
</template>
